import { PreferenceKey } from '@a_team/models/dist/UserPreferences';
import { Icon } from '@a_team/ui-components';
import { Breakpoints, Paddings, Colors, TextColors } from '@ateams/components';
import LinkedinReportProblemModal from '@src/components/Modal/LinkedinReportProblemModal';
import StickyStrip from '@src/components/StickyStrip';
import useToggle from '@src/hooks/useToggle';
import { ProfileLocation } from '@src/locations';
import {
  useGetUserPreferences,
  useUpdateUserPreference,
  useValidateLinkedInUrl,
} from '@src/rq/users';
import { useStores } from '@src/stores';
import { ProfileViewMode } from '@src/stores/Profile/models';
import cx from 'classnames';
import { differenceInDays } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';

interface LinkedinBannerProps {
  className?: string;
}

const useStyles = createUseStyles({
  banner: {
    width: '100%',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F63041',
    color: TextColors.primary,
    padding: '10px',
    position: 'relative',
  },
  bannerContent: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flex: 1,
    '& > button': {
      padding: '4px 0',
    },
  },
  bannerButton: {
    background: 'none',
    border: 'none',
    color: TextColors.primary,
    padding: 0,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  bannerDivider: {
    display: 'none',
  },
  bannerSide: {
    display: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
  },
  desktopIcon: {
    display: 'none',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    banner: {
      padding: '8px 24px',
      gap: '10px',
    },
    bannerContent: {
      justifyContent: 'center',
      gap: '8px',
      '& > button': {
        padding: 0,
      },
    },
    bannerDivider: {
      display: 'block',
      margin: '0 8px',
    },
    bannerSide: {
      display: 'block',
      maxWidth: '60px',
      width: '60px',
      flex: 'none',
    },
    closeButton: {
      display: 'none',
    },
    desktopIcon: {
      display: 'inline-flex',
    },
  },
});

export const LinkedinBanner = ({ className }: LinkedinBannerProps) => {
  const stores = useStores();
  const history = useHistory();
  const { auth, users } = stores;
  const { profile } = users;
  const styles = useStyles();
  const [
    isLinkedinReportProblemModalOpen,
    setIsLinkedinReportProblemModalOpen,
  ] = useToggle(false);

  const { mutate: updateUserPreference, isLoading: isUpdatingUserPreference } =
    useUpdateUserPreference();
  const { mutate: validateLinkedInUrl, data: validateLinkedInUrlData } =
    useValidateLinkedInUrl(auth.uid ?? '');
  const { isLoading: isLoadingUserPreferences, data: userPreferencesData } =
    useGetUserPreferences({
      enabled: auth.withLinkedinValidation === true,
      onSuccess: (data) => {
        const dismissedAt = data.preferences.dismissedLinkedinBannerAt;
        if (!dismissedAt) {
          validateLinkedInUrl();
          return;
        }

        const daysSinceDismissal = differenceInDays(
          new Date(),
          new Date(dismissedAt),
        );
        if (daysSinceDismissal >= 90) {
          validateLinkedInUrl();
        }
      },
    });

  const onDismissBanner = () => {
    updateUserPreference({
      key: PreferenceKey.DismissedLinkedinBannerAt,
      value: new Date(),
    });
  };

  const onUpdateLinkedinUrl = () => {
    const username = auth?.user?.username || '';
    history.push(`${ProfileLocation(username)}?section=linkedin&edit=true`);
  };

  // Add useEffect to handle URL parameters
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const section = params.get('section');
    const shouldEdit = params.get('edit');

    // Only proceed if profile username matches auth username
    if (
      section === 'linkedin' &&
      shouldEdit === 'true' &&
      profile?.data.username === auth?.user?.username
    ) {
      profile?.setProfileMode(ProfileViewMode.Edit);

      // Clear the URL parameters
      history.replace(window.location.pathname);

      // Scroll to section after a short delay to ensure component is mounted
      setTimeout(() => {
        const linkedinSection = document.getElementById('linkedin-section');
        if (linkedinSection) {
          linkedinSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 100);
    }
  }, [
    history,
    profile,
    profile?.data.username,
    auth?.user?.username,
    history.location.search,
  ]);

  const onReportProblem = () => {
    setIsLinkedinReportProblemModalOpen(true);
  };

  const isLoading = isLoadingUserPreferences || isUpdatingUserPreference;
  const hasValidUser = !!auth?.user;
  const hasBeenValidated = validateLinkedInUrlData?.wasValidated;
  const isLinkedInUrlValid =
    hasBeenValidated && validateLinkedInUrlData?.isValid;
  const validatedUsername = validateLinkedInUrlData?.username;

  const lastDismissalDate =
    userPreferencesData?.preferences.dismissedLinkedinBannerAt;
  const isDismissalStillActive =
    lastDismissalDate &&
    differenceInDays(new Date(), new Date(lastDismissalDate)) < 90;

  const shouldHideBanner =
    isLoading ||
    !hasValidUser ||
    !hasBeenValidated ||
    isLinkedInUrlValid ||
    isDismissalStillActive ||
    auth.withLinkedinValidation !== true;

  if (shouldHideBanner) {
    return null;
  }

  return (
    <>
      <StickyStrip
        styles={{
          background: Colors.danger,
          color: Colors.light,
          padding: Paddings.small,
        }}
      >
        <div className={cx(styles.banner, className)}>
          <div className={styles.bannerSide} />
          <div className={styles.bannerContent}>
            <span className={styles.desktopIcon}>
              <Icon name="statusNegative" color="Grey@100" />
            </span>
            We couldn’t verify your LinkedIn URL. Update it to keep your profile
            accurate and stand out to clients.
            <button
              className={styles.bannerButton}
              onClick={onUpdateLinkedinUrl}
            >
              Update link
            </button>
            <span className={styles.bannerDivider}>|</span>
            <button className={styles.bannerButton} onClick={onReportProblem}>
              Report an error
            </button>
          </div>
          <div className={styles.bannerSide}>
            <button className={styles.bannerButton} onClick={onDismissBanner}>
              Dismiss
            </button>
          </div>
          <button className={styles.closeButton} onClick={onDismissBanner}>
            <Icon name="statusNegativeNoBorder" size="sm" color="Grey@100" />
          </button>
        </div>
      </StickyStrip>
      <LinkedinReportProblemModal
        defaultLinkedinUsername={validatedUsername}
        isOpen={isLinkedinReportProblemModalOpen}
        onClose={setIsLinkedinReportProblemModalOpen}
      />
    </>
  );
};

export default observer(LinkedinBanner);
